import Gallery1 from "@assets/images/newpoto/gallery/bg-gallery1.webp";
import GalleryT1 from "@assets/images/newpoto/thumbnail/t-1.webp";
import Gallery2 from "@assets/images/newpoto/gallery/bg-gallery2.webp";
import GalleryT2 from "@assets/images/newpoto/thumbnail/t-2.webp";
import Gallery3 from "@assets/images/newpoto/gallery/bg-gallery3.webp";
import GalleryT3 from "@assets/images/newpoto/thumbnail/t-3.webp";
import Gallery4 from "@assets/images/newpoto/gallery/bg-gallery4.webp";
import GalleryT4 from "@assets/images/newpoto/thumbnail/t-4.webp";
import Gallery5 from "@assets/images/newpoto/gallery/bg-gallery5.webp";
import GalleryT5 from "@assets/images/newpoto/thumbnail/t-5.webp";
import Gallery6 from "@assets/images/newpoto/gallery/bg-gallery6.webp";
import GalleryT6 from "@assets/images/newpoto/thumbnail/t-6.webp";
import Gallery7 from "@assets/images/newpoto/gallery/bg-gallery7.webp";
import GalleryT7 from "@assets/images/newpoto/thumbnail/t-7.webp";
import Gallery8 from "@assets/images/newpoto/gallery/bg-gallery8.webp";
import GalleryT8 from "@assets/images/newpoto/thumbnail/t-8.webp";
import Gallery10 from "@assets/images/newpoto/gallery/bg-gallery10.webp";
import GalleryT10 from "@assets/images/newpoto/thumbnail/t-9.webp";
import Gallery11 from "@assets/images/newpoto/gallery/bg-gallery11.webp";
import GalleryT11 from "@assets/images/newpoto/thumbnail/t-11.webp";
import Gallery12 from "@assets/images/newpoto/gallery/bg-gallery12.webp";
import GalleryT12 from "@assets/images/newpoto/thumbnail/t-12.webp";

export const photos = [
  {
    original: Gallery11,
    thumbnail: GalleryT11,
  },
  {
    original: Gallery12,
    thumbnail: GalleryT12,
  },
  {
    original: Gallery1,
    thumbnail: GalleryT1,
  },
  {
    original: Gallery2,
    thumbnail: GalleryT2,
  },
  {
    original: Gallery3,
    thumbnail: GalleryT3,
  },
  {
    original: Gallery4,
    thumbnail: GalleryT4,
  },
  {
    original: Gallery5,
    thumbnail: GalleryT5,
  },
  {
    original: Gallery6,
    thumbnail: GalleryT6,
  },
  {
    original: Gallery7,
    thumbnail: GalleryT7,
  },
  {
    original: Gallery8,
    thumbnail: GalleryT8,
  },
  {
    original: Gallery10,
    thumbnail: GalleryT10,
  },
];

import React from "react";
import ImageGallery from "react-image-gallery";
// import Gallery from "react-photo-gallery";
import "react-image-gallery/styles/css/image-gallery.css";
import { photos } from "./photo-data";
import Gallery from "react-grid-gallery";

function PhotoSection() {
  function columns(containerWidth) {
    let columns = 1;
    if (containerWidth >= 500) columns = 2;
    if (containerWidth >= 900) columns = 3;
    if (containerWidth >= 1500) columns = 4;
    return columns;
  }

  // const IMAGES = [
  //   {
  //     src: "https://c2.staticflickr.com/9/8817/28973449265_07e3aa5d2e_b.jpg",
  //     thumbnail:
  //       "https://c2.staticflickr.com/9/8817/28973449265_07e3aa5d2e_n.jpg",
  //     thumbnailWidth: 320,
  //     thumbnailHeight: 174,
  //     // isSelected: true,
  //     caption: "After Rain (Jeshu John - designerspics.com)",
  //   },
  //   {
  //     src: "https://c2.staticflickr.com/9/8356/28897120681_3b2c0f43e0_b.jpg",
  //     thumbnail:
  //       "https://c2.staticflickr.com/9/8356/28897120681_3b2c0f43e0_n.jpg",
  //     thumbnailWidth: 320,
  //     thumbnailHeight: 212,
  //     tags: [
  //       { value: "Ocean", title: "Ocean" },
  //       { value: "People", title: "People" },
  //     ],
  //     caption: "Boats (Jeshu John - designerspics.com)",
  //   },

  //   {
  //     src: "https://c4.staticflickr.com/9/8887/28897124891_98c4fdd82b_b.jpg",
  //     thumbnail:
  //       "https://c4.staticflickr.com/9/8887/28897124891_98c4fdd82b_n.jpg",
  //     thumbnailWidth: 320,
  //     thumbnailHeight: 212,
  //   },
  // ];

  return (
    <div id="fh5co-testimonial" className="fh5co-section-gray">
      <div className="container">
        <div className="row">
          <div className="row">
            <div className="col-md-8 col-md-offset-2 text-center fh5co-heading">
              <h2 className="main-font">#JanuIndra</h2>
              <p className="sub-title">
                "Dan di antara tanda-tanda (kebesaran)-Nya ialah Dia menciptakan
                pasangan-pasangan untukmu dari jenismu sendiri, agar kamu
                cenderung dan merasa tenteram kepadanya, dan Dia menjadikan di
                antaramu rasa kasih dan sayang. Sungguh, pada yang demikian itu
                benar-benar terdapat tanda-tanda (kebesaran Allah) bagi kaum
                yang berpikir" (Surah Ar-Rum Ayat 20)
              </p>
            </div>
          </div>
          {/* <div className="row">
            <div className="col-md-10 col-md-offset-1">{renderYoutubeVideo()}</div>
          </div> */}
          <div className="row">
            <div className="col-md-10 col-md-offset-1">
              <ImageGallery items={photos} showBullets={false} />;
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PhotoSection;
